
import { Options, Vue } from 'vue-class-component';
import { Prop, PropSync } from 'vue-property-decorator';
import { $api } from '@/services';
import { generateReport } from '@/helper/generateReport';
import { _GET_DATE_TIME } from '@/helper/formatDate';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import InviteError from '@/views/record/studentDetail/features/inviteParent/InviteError.vue';

@Options({
	name: 'InviteParentPopup',
	components: { InviteError, DaModalFrame },
})
export default class InviteParentPopup extends Vue {
	@PropSync('invited') _invited = false;
	@Prop() studentName!: string;

	email = '';
	error = '';
	data = null as any;
	deleteErrorPopup = false;

	get id() {
		return String(this.$route.params.id || '');
	}

	submit() {
		this.sendInviteParent(true);
		this.deleteErrorPopup = false;
	}

	async sendInviteParent(ignore_parent_deleted: boolean) {
		this.error = '';

		try {
			const { data } = await $api.student.sendInviteParent(
				this.id,
				this.email,
				ignore_parent_deleted || false
			);
			if (data) {
				this.data = data.data;
				await $api.student.sendEmailInviteParent(
					this.data.student_id,
					this.data.id
				);
				this._invited = true;
			}
			this.$emit('invited');
		} catch (err) {
			if (err?.response?.data?.code === 'parent_deleted') {
				this.deleteErrorPopup = true;
			} else if (err?.response?.data?.errors_by_field?.email) {
				this.error =
					'Email ' +
						err?.response?.data?.errors_by_field?.email.join(
							'<br/>'
						) || err.message;
			} else {
				this.error =
					err?.response?.data?.errors.join('<br/>') || err.message;
			}
		}
	}

	get org() {
		return (this as any).$store.state.profile.current_org;
	}

	generateReport() {
		this.error = '';

		let fileName = this.data.email + '_' + this.studentName;
		fileName = fileName.toLowerCase();
		fileName = fileName.replace(/\s/g, '_');
		return generateReport({
			fileName: fileName + '.pdf',
			orgName: this.org.name,
			studentName: this.studentName,
			email: this.data.email,
			code: this.data.code,
			date: _GET_DATE_TIME(this.data?.valid_until),
		});
	}
}
