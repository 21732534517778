
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import { _ROUTER_NAME } from '@/enums';
import { useStudent } from '@/features/student/useStudent';
import { useStore } from 'vuex';
import Multiselect from '@suadelabs/vue3-multiselect';
import Loading from '@/components/common/Loading.vue';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import SelectCenter from '@/components/elements/selectCenter/SelectCenter.vue';
import { $api } from '@/services';
import { _MONTH_DIFF } from '@/helper/monthDiff';
import { _GET_DATE_2 } from '@/helper/formatDate';
import { studentState } from '@/helper/studentState';

export default defineComponent({
	name: 'StudentDetail',
	methods: { studentState },
	components: { SelectCenter, DaModalFrame, Loading, DaIcon, Multiselect },
	setup() {
		const id = ref('');
		const router = useRouter();
		const student: any = ref(null);
		const success = ref(false);
		const isLoading = ref(true);
		const tab = ref({
			label: '',
			value: '',
		} as any);
		const tabs = computed(() => {
			let data = [
				{
					label: 'Trị liệu viên giảng dạy',
					value: 'record_student_detail_staff',
				},
				{
					label: 'Thông tin học sinh',
					value: 'record_student_detail_info',
				},
				{
					label: 'Kết quả đánh giá',
					value: 'record_student_detail_result',
				},
			];

			if (!['pending'].includes(student.value?.state)) {
				data = [
					{
						label: 'Thống kê',
						value: _ROUTER_NAME.RECORD_STUDENT_STATS,
					},
					...data,
				];
			}

			return data;
		});

		const route = useRoute();
		const store = useStore();
		const modalIsOpen = ref(false);
		id.value = route.params.id ? route.params.id + '' : '';

		const currentOrgId = ref(store.state.profile.current_org.org_id);
		const orgHistory = ref([]);
		const orgOptions = ref([]);

		const { getStudent, updateStudent, closeStudent } = useStudent();

		watch(
			route,
			(value) => {
				console.log('route', value)
				const name = value?.name;
				tab.value = tabs.value.find((item) => item.value == name);
				if (value.name == _ROUTER_NAME.RECORD_STUDENT_DETAIL_INFO) {
					fetchStudent();
				}
			},
			{
				immediate: true,
				deep: true,
			}
		);

		watch(tab, (value: { value: string }) => {
			console.log('tab', tab)
			if (value) {
				router.push({
					name: value.value,
					query: {
						...route.query,
					},
				});
			}
		});

		onMounted(() => {
			const name = route?.name;
			console.log('route', route)
			tab.value = tabs.value.find((item) => item.value == name);
			console.log('tab.value', tab.value)
		});

		const oldPayload = ref({
			'x-cross': route.query.org_id,
		});

		const is_old_data = ref(route.query.is_old_data === 'true');

		async function fetchStudent() {
			const res = await getStudent(
				id.value,
				is_old_data.value ? oldPayload.value : null
			);

			if (res?.data) {
				student.value = res?.data;
			}

			if (['pending'].includes(student.value?.state)) {
				await router.push({
					name: _ROUTER_NAME.RECORD_STUDENT_DETAIL_INFO,
					query: route.query,
				});
			}

			isLoading.value = false;
		}

		async function updateStudentSubmit(data: any) {
			const res = await updateStudent(id.value, {
				student: data,
			});
			if (res?.data) {
				student.value = res?.data;
				await store.dispatch('setAlertMessage', {
					message: 'Cập nhật thông tin học sinh thành công',
				});
			}
		}

		async function closeStudentSubmit(value: {
			isActive: boolean;
			id: string;
			state: string;
		}) {
			const res = await closeStudent(
				value.id,
				value.isActive,
				value.state
			);
			if (res?.data) {
				success.value = true;
				await fetchStudent();
			}
			if (res?.data?.message) {
				await store.dispatch('setAlertMessage', {
					message: res?.data?.message,
				});
			}
		}

		function openModal() {
			success.value = false;
		}

		async function fetchOrgTransferHistory() {
			try {
				const {
					data: { data },
				} = await $api.student.fetchOrgTransferHistoryOlCenter(
					id.value
				);
				orgOptions.value = data.filter(
					(org: any) => org.org.org_id !== currentOrgId.value
				);
			} catch (e) {
				console.log(e);
			}
		}

		async function fetchOrgTransferHistoryData() {
			try {
				const {
					data: { data },
				} = await $api.student.fetchOrgTransferHistory(
					id.value,
					is_old_data.value ? oldPayload.value : null
				);
				orgHistory.value = data;
			} catch (e) {
				console.log(e);
			}
		}

		function openModalSelectCenter() {
			if (orgOptions.value.length > 1) {
				modalIsOpen.value = true;
			} else if (orgOptions.value.length === 1) {
				selectedOrg(orgOptions.value[0]);
			}
		}

		function updatedAvatar() {
			fetchStudent();
		}

		const isTeacherAndAccountant = computed(
			() => store.getters.isTeacherAndAccountant
		);

		const tabOptions = computed(() =>
			tabs.value.filter((item) => {
				return item;
			})
		);

		async function selectedOrg(org: any) {
			const query = route.query;
			let r = router.resolve({
				path: `/record/student/${org.old_student_id}/staff-list`,
				query: {
					...query,
					org: org.org.subdomain,
					org_id: org.org.org_id,
					is_old_data: 'true',
					join_date: org.join_date,
					left_date: org.left_date,
					action: 'full-screen',
					new_id: id.value,
				},
			});
			window.location.assign(r.href);
		}

		async function viewReport() {
			const query = route.query;
			await router.push({
				path: `/student/${id.value}/old-data`,
				query: {
					...query,
				},
			});
		}

		const dateData = computed(() => {
			const join_date = route.query.join_date;
			const left_date = route.query.left_date;
			if (!join_date) {
				return '';
			}
			return `${_GET_DATE_2(join_date)} - ${
				left_date ? _GET_DATE_2(left_date) : 'Hiện tại'
			} | Đã học ${_MONTH_DIFF(join_date, left_date)} tháng`;
		});

		fetchStudent();

		if (!is_old_data.value) {
			fetchOrgTransferHistory();
		}

		fetchOrgTransferHistoryData();

		function exit() {
			const new_id = route.query.new_id;
			const r = router.resolve({
				path: `/record/student/${new_id}/staff-list`,
			});
			window.location.assign(r.href);
		}

		function viewPlan() {
			const query = route.query;
			return router.push({
				path: `/plans/${student.value?.id}`,
				query: {
					...query,
				},
			});
		}

		return {
			updatedAvatar,
			tab,
			tabOptions,
			student,
			success,
			routerName: computed(() => _ROUTER_NAME),
			updateStudentSubmit,
			closeStudentSubmit,
			openModal,
			isTeacherAndAccountant,
			isLoading,
			orgHistory,
			orgOptions,
			modalIsOpen,
			openModalSelectCenter,
			selectedOrg,
			is_old_data,
			dateData,
			viewReport,
			viewPlan,
			exit,
		};
	},
});
