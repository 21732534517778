<template>
	<div :class="isShow ? 'side-over--active' : ''">
		<div
			v-if="isShow"
			class="
				fixed
				h-screen
				w-screen
				top-0
				left-0
				bg-black
				opacity-20
				z-30
			"
			@click="isShow = false"
		></div>
		<UpdateResultSideOver
			v-model:is-data="isData"
			v-model:refresh="isShow"
			@close="isShow = false"
			@success="success"
		/>
	</div>
	<div v-if="!isLoading">
		<div v-if="result && result.length > 0 && alertShow" class="mb-10">
			<div
				class="bg-yellow-50 border-l-4 border-yellow-600 p-4 rounded-md"
			>
				<div class="relative">
					<div class="flex items-center gap-4">
						<div>
							<img
								alt="luca-logo"
								height="24"
								src="@/assets/images/logo/logo.svg"
								width="35"
							/>
						</div>
						<div class="pt-1 text-neutral-600 text-sm">
							Các kết quả trong mục này sẽ được hiển thị trên app
							Phụ Huynh
						</div>
					</div>
					<div
						class="top-1 right-2 absolute cursor-pointer"
						@click="alertShow = false"
					>
						<svg
							fill="none"
							height="12"
							viewBox="0 0 12 12"
							width="12"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								clip-rule="evenodd"
								d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6 4.58579L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893C12.0976 0.683417 12.0976 1.31658 11.7071 1.70711L7.41421 6L11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071C11.3166 12.0976 10.6834 12.0976 10.2929 11.7071L6 7.41421L1.70711 11.7071C1.31658 12.0976 0.683417 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
								fill="#E5A42B"
								fill-rule="evenodd"
							/>
						</svg>
					</div>
				</div>
			</div>
		</div>
		<div
			v-if="result && result.length > 0"
			class="flex items-center justify-end"
		>
			<div
				v-if="
					!isTeacherAndAccountant &&
					!is_old_data &&
					student.state === 'active'
				"
				class="my-6 ml-auto"
			>
				<button
					class="
						button-p
						text-center
						bg-none
						shadow-none
						flex
						items-center
						justify-center
						btn--text
						w-fit
					"
					type="button"
					@click="isShow = true"
				>
					<svg
						class="mr-2"
						fill="none"
						height="16"
						viewBox="0 0 16 16"
						width="16"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							clip-rule="evenodd"
							d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.5 4.5C8.5 4.22386 8.27614 4 8 4C7.72386 4 7.5 4.22386 7.5 4.5V7.5H4.5C4.22386 7.5 4 7.72386 4 8C4 8.27614 4.22386 8.5 4.5 8.5H7.5V11.5C7.5 11.7761 7.72386 12 8 12C8.27614 12 8.5 11.7761 8.5 11.5V8.5H11.5C11.7761 8.5 12 8.27614 12 8C12 7.72386 11.7761 7.5 11.5 7.5H8.5V4.5Z"
							fill="currentColor"
							fill-rule="evenodd"
						/>
					</svg>
					Tạo kết quả đánh giá mới
				</button>
			</div>
		</div>
		<div
			v-if="result && result.length > 0"
			class="shadow-sm sm:rounded-2xl overflow-hidden"
		>
			<div class="__record__table overflow-x-auto scroll-bar">
				<div class="overflow-hidden sm:rounded-t-2xl w-fit min-w-full">
					<table class="min-w-full divide-y text-neutral-200">
						<thead class="bg-neutral-100 h-16">
							<tr>
								<th
									class="
										px-6
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										whitespace-nowrap
									"
									scope="col"
								>
									Tên bảng đánh giá
								</th>
								<th
									class="
										px-3
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										whitespace-nowrap
									"
									scope="col"
								>
									Ngày thực hiện
								</th>
								<th
									class="
										px-3
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										whitespace-nowrap
									"
									scope="col"
								>
									Tuổi thực
								</th>
								<th
									class="
										px-3
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										whitespace-nowrap
									"
									scope="col"
								>
									Tuổi phát triển
								</th>
								<th
									class="
										px-3
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										whitespace-nowrap
									"
									scope="col"
								>
									Kết luận
								</th>
								<th
									class="
										px-3
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										whitespace-nowrap
									"
									scope="col"
								>
									Đơn thuốc
								</th>
								<th
									class="
										px-3
										py-3
										text-left text-xs
										font-semibold
										text-neutral-600
										uppercase
										tracking-wider
										whitespace-nowrap
									"
									scope="col"
								>
									Tài liệu
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="(item, index) in result"
								:key="item.id"
								:class="[
									index % 2 === 0
										? 'bg-white'
										: 'bg-neutral-50',
									item?.state === 'active'
										? 'bg-orange-50'
										: '',
								]"
								class="
									text-neutral-700
									hover:bg-orange-50
									cursor-pointer
								"
								@click="
									$router.push({
										path: `/record/student/${id}/result/${item?.id}`,
										query: {
											start_date:
												item?.period_report?.start_date,
											end_date:
												item?.period_report?.end_date,
										},
									})
								"
							>
								<td
									class="
										px-6
										py-4
										whitespace-nowrap
										text-neutral-700
										capitalize
									"
								>
									{{ item.name }}
								</td>
								<td
									class="
										px-3
										py-4
										whitespace-nowrap
										text-neutral-700
									"
								>
									<div v-if="item.assessment_date">
										{{
											$filters.formatDate2(
												item.assessment_date
											)
										}}
									</div>
								</td>
								<td
									class="
										px-3
										py-4
										whitespace-nowrap
										text-neutral-700
									"
								>
									{{ item.age }}
								</td>
								<td
									class="
										px-3
										py-4
										whitespace-nowrap
										text-neutral-700
									"
								>
									<div
										v-for="(
											d, index
										) in item.development_ages"
										:key="index"
									>
										{{ d }}
									</div>
								</td>
								<td
									class="
										px-3
										py-4
										whitespace-nowrap
										text-neutral-700
									"
								>
									<div
										v-for="(
											d, index
										) in item.assessment_conclusions"
										:key="index"
									>
										{{ d }}
									</div>
								</td>
								<td
									class="
										px-3
										py-4
										whitespace-nowrap
										text-neutral-700
									"
								>
									<div
										v-for="(d, index) in item.prescription"
										:key="index"
									>
										{{ d }}
									</div>
								</td>
								<td>
									<div v-if="item.files.length" class="ml-4">
										<svg
											class="
												cursor-pointer
												text-blue-700
												hover:text-blue-800
											"
											fill="none"
											height="20"
											viewBox="0 0 20 20"
											width="20"
											xmlns="http://www.w3.org/2000/svg"
											@click.stop="
												downloadFileResult(item.id)
											"
										>
											<path
												clip-rule="evenodd"
												d="M10 0C8.21698 0 6.6397 0.70057 5.5072 1.67727C4.54981 2.50296 3.85599 3.57713 3.67778 4.65625C1.58233 5.1189 0 6.94389 0 9.14773C0 11.7075 2.13442 13.75 4.72656 13.75H9.375V6.875C9.375 6.52982 9.65482 6.25 10 6.25C10.3452 6.25 10.625 6.52982 10.625 6.875V13.75H15.8594C18.1279 13.75 20 11.9619 20 9.71591C20 7.6707 18.4476 6.00513 16.4571 5.72373C16.1541 2.4993 13.3628 0 10 0Z"
												fill="currentColor"
												fill-rule="evenodd"
											/>
											<path
												clip-rule="evenodd"
												d="M9.55806 19.8169C9.80214 20.061 10.1979 20.061 10.4419 19.8169L14.1919 16.0669C14.436 15.8229 14.436 15.4271 14.1919 15.1831C13.9479 14.939 13.5521 14.939 13.3081 15.1831L10.625 17.8661V13.75H9.375V17.8661L6.69194 15.1831C6.44786 14.939 6.05214 14.939 5.80806 15.1831C5.56398 15.4271 5.56398 15.8229 5.80806 16.0669L9.55806 19.8169Z"
												fill="currentColor"
												fill-rule="evenodd"
											/>
										</svg>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div
			v-else
			class="w-full h-full flex items-center justify-center flex-col"
		>
			<img
				alt=""
				height="250"
				src="@/assets/images/illustrations/not_found.png"
				width="250"
			/>
			<div
				class="text-neutral-500 text-lg text-center"
				style="max-width: 700px"
			>
				Học sinh chưa có Kết quả đánh giá (bao gồm kết quả đánh giá đầu
				vào, chẩn đoán, lượng giá...)
				<br />
				<br />
				Các kết quả trong mục này sẽ được hiển thị trên app Phụ Huynh
			</div>
			<div
				v-if="
					isAdmin &&
					!is_old_data &&
					['active', 'pending'].includes(student.state)
				"
				class="mt-8"
			>
				<button
					class="button-p btn--ghost w-fit"
					type="button"
					@click="isShow = true"
				>
					<span class="ml-2 whitespace-nowrap"
						>Tạo kết quả đánh giá mới</span
					>
				</button>
			</div>
		</div>
	</div>
	<div v-else class="fixed top-1/2 left-1/2">
		<Loading />
	</div>
</template>

<script>
import { defineComponent } from 'vue';
import { $api } from '@/services';
import UpdateResultSideOver from './UpdateResultSideOver';
import downloadFile from '@/mixins/downloadCSV.vue';
import Loading from '@/components/common/Loading';

export default defineComponent({
	name: 'Result',
	components: { Loading, UpdateResultSideOver },
	data() {
		return {
			result: [],
			isShow: false,
			isData: false,
			alertShow: true,
			isLoading: true,
		};
	},
	props: {
		student: {
			type: Object,
			default() {
				return {
					id: '',
					state: '',
				};
			},
		},
	},

	mixins: [downloadFile],

	computed: {
		id() {
			return Number(this.$route.params.id);
		},
		isTeacherAndAccountant() {
			return this.$store.getters.isTeacherAndAccountant;
		},
		isAdmin() {
			return this.$store.getters.isAdmin;
		},
		oldPayload() {
			return {
				'x-cross': this.$route.query.org_id,
			};
		},

		is_old_data() {
			return this.$route.query.is_old_data === 'true';
		},
	},

	created() {
		this.fetchResult();
	},

	methods: {
		downloadFileResult(id) {
			this.fetchResultDetail(id);
		},

		async fetchResultDetail(id) {
			try {
				const { data } = await $api.student.assessmentDocumentDetail(
					this.id,
					id,
					this.is_old_data ? this.oldPayload : null
				);
				this.downloadFileAsZip(data.data.files);
			} catch (e) {
				await this.$store.dispatch('setAlertMessage', {
					message: 'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		},

		async fetchResult() {
			try {
				const { data } = await $api.student.assessmentDocuments(
					this.id,
					this.is_old_data ? this.oldPayload : null
				);
				this.result = data.data || [];
			} catch (e) {
				await this.$store.dispatch('setAlertMessage', {
					message: 'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				this.isLoading = false;
			}
		},

		success() {
			this.fetchResult();
			this.isData = true;
		},
	},
});
</script>
