<template>
	<div class="grid grid-cols-2 min-h-screen">
		<div class="__setup-center px-12 md-down:px-4 pb-30">
			<div
				class="
					__setup-center__header
					pt-10
					pb-4
					flex
					justify-between
					gap-8
					items-center
					lg:flex-nowrap
					flex-wrap
				"
			>
				<div
					class="
						__record__title
						text-neutral-500
						font-semibold
						whitespace-nowrap
						flex flex-nowrap
						items-center
						text-4xl
						cursor-pointer
					"
					@click="$router.back()"
				>
					<svg
						class="mr-2"
						fill="none"
						height="28"
						viewBox="0 0 28 28"
						width="28"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M17.5 7L10.5 14L17.5 21"
							stroke="#94A3B8"
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
						/>
					</svg>
					Thêm học sinh
				</div>
				<div
					class="search-input w-80 lg-down:ml-auto"
					style="min-width: 120px"
				>
					<div class="input-group input--xl">
						<input
							v-model="searchQuery"
							class="bg-white input-with-icon"
							placeholder="Tìm theo học sinh"
							type="text"
							@keyup="searching"
							@keypress.enter="search"
						/>
						<div class="input-icon absolute top-1/2">
							<DaIcon
								class="h-4 w-4 text-neutral-400"
								name="das--search"
							/>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div>
					<div
						v-if="students.length"
						class="font-semibold text-neutral-500"
					>
						Chọn học sinh để thêm vào lớp nhóm
					</div>
					<div
						v-if="students.length"
						class="shadow-sm sm:rounded-2xl mt-12"
					>
						<div class="__record__table overflow-x-auto scroll-bar">
							<div
								class="
									overflow-hidden
									sm:rounded-t-2xl
									w-fit
									min-w-full
								"
							>
								<table
									class="min-w-full divide-y text-neutral-200"
								>
									<thead class="bg-neutral-100 h-16">
										<tr>
											<th
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													w-16
													whitespace-nowrap
												"
												scope="col"
											>
												STT
											</th>
											<th
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
												scope="col"
											>
												Tên học sinh
											</th>
											<th class="w-8"></th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="(
												person, index
											) in this.students.filter(
												(s) =>
													!this.checkBoxSelected.includes(
														s.id
													)
											)"
											:key="person.id"
											:class="
												index % 2 === 0
													? 'bg-white'
													: 'bg-neutral-50'
											"
											class="
												text-neutral-700
												hover:bg-orange-50
												cursor-pointer
											"
											@click="
												$router.push(
													isAdmin
														? `/record/student/${person.id}`
														: `/record/student/${person.id}/info`
												)
											"
										>
											<td>
												<div
													class="
														px-6
														py-4
														text-neutral-700
													"
												>
													{{ index + 1 }}
												</div>
											</td>
											<td
												class="
													px-6
													py-4
													whitespace-nowrap
													text-neutral-700
												"
												style="width: 250px"
											>
												<div class="flex items-center">
													<div>
														<img
															v-if="
																person
																	?.profile_photo
																	?.w200
															"
															:src="
																person
																	?.profile_photo
																	?.w200
															"
															alt=""
															class="
																shadow-sm
																inline-block
																h-6
																w-6
																rounded-full
															"
														/>
														<span
															v-else
															:style="{
																background:
																	person
																		?.profile_photo
																		?.default
																		?.color,
															}"
															class="
																inline-flex
																items-center
																justify-center
																h-6
																w-6
																rounded-full
															"
														>
															<span
																class="
																	font-medium
																	leading-none
																	text-white
																	text-xs
																"
																>{{
																	person
																		?.profile_photo
																		?.default
																		?.initial
																}}</span
															>
														</span>
													</div>
													<div
														class="
															ml-4
															text-orange-600
															font-semibold
															truncate
														"
													>
														{{ person.name }}
													</div>
												</div>
											</td>
											<td>
												<Checkbox
													:value="
														checkBoxSelected.find(
															(id) =>
																id === person.id
														)
													"
													@click.stop="
														checkChanged(person.id)
													"
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div
						v-else
						class="
							w-full
							h-full
							mt-20
							flex
							items-center
							justify-center
							flex-col
						"
					>
						<img
							alt=""
							height="250"
							src="@/assets/images/illustrations/not_found.png"
							width="250"
						/>
						<div
							class="text-neutral-500 text-lg text-center"
							style="max-width: 700px"
						>
							Rất tiếc, chúng tôi không thể tìm thấy kết quả cho
							tìm kiếm của bạn.
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="bg-white p-12">
			<div class="font-semibold text-neutral-600">
				Danh sách học sinh đã chọn
			</div>
			<div class="text-neutral-400 mt-1">
				Danh sách học sinh đã chọn sẽ được thêm vào lớp nhóm
			</div>
			<div class="flex items-center gap-5 mt-6">
				<div class="block w-1/2 flex items-center gap-2 py-4 px-6">
					<svg
						fill="none"
						height="28"
						viewBox="0 0 28 28"
						width="28"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							clip-rule="evenodd"
							d="M12.25 24.5C12.25 24.5 10.5 24.5 10.5 22.75C10.5 21 12.25 15.75 19.25 15.75C26.25 15.75 28 21 28 22.75C28 24.5 26.25 24.5 26.25 24.5H12.25Z"
							fill="#65758B"
							fill-rule="evenodd"
						/>
						<path
							clip-rule="evenodd"
							d="M19.25 14C22.1495 14 24.5 11.6495 24.5 8.75C24.5 5.85051 22.1495 3.5 19.25 3.5C16.3505 3.5 14 5.85051 14 8.75C14 11.6495 16.3505 14 19.25 14Z"
							fill="#65758B"
							fill-rule="evenodd"
						/>
						<path
							clip-rule="evenodd"
							d="M9.12863 24.5C8.88223 24.0029 8.75 23.4135 8.75 22.75C8.75 20.378 9.93834 17.9385 12.1379 16.2399C11.1757 15.9322 10.0539 15.75 8.75 15.75C1.75 15.75 0 21 0 22.75C0 24.5 1.75 24.5 1.75 24.5H9.12863Z"
							fill="#65758B"
							fill-rule="evenodd"
						/>
						<path
							clip-rule="evenodd"
							d="M7.875 14C10.2912 14 12.25 12.0412 12.25 9.625C12.25 7.20875 10.2912 5.25 7.875 5.25C5.45875 5.25 3.5 7.20875 3.5 9.625C3.5 12.0412 5.45875 14 7.875 14Z"
							fill="#65758B"
							fill-rule="evenodd"
						/>
					</svg>
					<div class="font-semibold" style="color: #65758b">
						{{ groupClass.name }}
					</div>
				</div>
				<div
					class="
						block
						w-1/2
						flex
						items-center
						justify-between
						gap-2
						py-4
						px-6
					"
				>
					<div
						class="font-normal"
						style="color: #65758b; font-size: 14px"
					>
						Học sinh
					</div>
					<div class="text-4xl font-bold" style="color: #48566a">
						{{ groupClass.students_count }}
					</div>
				</div>
			</div>
			<div>
				<div class="flex flex-col">
					<div
						v-if="allStudents.length"
						class="shadow-sm sm:rounded-2xl mt-6"
					>
						<div class="__record__table overflow-x-auto scroll-bar">
							<div
								class="
									overflow-hidden
									sm:rounded-t-2xl
									w-fit
									min-w-full
								"
							>
								<table
									class="min-w-full divide-y text-neutral-200"
								>
									<thead class="bg-neutral-100 h-16">
										<tr>
											<th
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													w-16
													whitespace-nowrap
												"
												scope="col"
											>
												STT
											</th>
											<th
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
												scope="col"
											>
												Tên học sinh
											</th>
											<th class="w-8"></th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="(
												person, index
											) in studentsInGroup"
											:key="person.id"
											:class="
												index % 2 === 0
													? 'bg-white'
													: 'bg-neutral-50'
											"
											class="
												text-neutral-700
												hover:bg-orange-50
												cursor-pointer
											"
											@click="
												$router.push(
													isAdmin
														? `/record/student/${person.id}`
														: `/record/student/${person.id}/info`
												)
											"
										>
											<td>
												<div
													class="
														px-6
														py-4
														text-neutral-700
													"
												>
													{{ index + 1 }}
												</div>
											</td>
											<td
												class="
													px-6
													py-4
													whitespace-nowrap
													text-neutral-700
												"
												style="width: 250px"
											>
												<div class="flex items-center">
													<div>
														<img
															v-if="
																person
																	?.profile_photo
																	?.w200
															"
															:src="
																person
																	?.profile_photo
																	?.w200
															"
															alt=""
															class="
																shadow-sm
																inline-block
																h-6
																w-6
																rounded-full
															"
														/>
														<span
															v-else
															:style="{
																background:
																	person
																		?.profile_photo
																		?.default
																		?.color,
															}"
															class="
																inline-flex
																items-center
																justify-center
																h-6
																w-6
																rounded-full
															"
														>
															<span
																class="
																	font-medium
																	leading-none
																	text-white
																	text-xs
																"
																>{{
																	person
																		?.profile_photo
																		?.default
																		?.initial
																}}</span
															>
														</span>
													</div>
													<div
														class="
															ml-4
															text-orange-600
															font-semibold
															whitespace-pre-wrap
															w-40
														"
													>
														{{ person.name }}
													</div>
												</div>
											</td>
											<td>
												<Checkbox
													:value="
														checkBoxSelected.find(
															(id) =>
																id === person.id
														)
													"
													@click.stop="
														checkChanged(person.id)
													"
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div class="mt-6 flex justify-end">
					<button
						class="button-p text-center w-32"
						type="button"
						@click="addStudents"
					>
						Xác nhận
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import DaIcon from '@/components/elements/daIcon/DaIcon';
import { $api } from '@/services';
import Checkbox from '@/components/elements/checkbox/Checkbox';
import { toEnglish } from '@/helper/toEnglish';

export default {
	name: 'GroupClassAddStudents',

	components: { Checkbox, DaIcon },

	data() {
		return {
			searchQuery: '',
			students: [],
			allStudents: [],
			allStudentsAdded: [],
			params: {},
			checkBoxSelected: [],
			classDetail: {},
			typing: null,
			groupClass: {},
		};
	},

	created() {
		this.fetchStudentsNotInGroupClass();
		this.fetchGroupClassDetail();
	},

	computed: {
		id() {
			return String(this.$route.params.id);
		},

		studentsInGroup() {
			return this.allStudents.filter((s) =>
				this.checkBoxSelected.includes(s.id)
			);
		},

		isAdmin() {
			return this.$store.getters.isAdmin;
		},
	},

	methods: {
		async fetchStudentsNotInGroupClass() {
			try {
				let role =
					this.$store.state.profile.staff.role === 'teacher'
						? 'staff'
						: 'org_admin';
				const {
					data: { data },
				} = await $api.student.getStudents(role, {
					state: 'active',
				});
				const {
					data: { data: InGroup },
				} = await $api.groupClasses.getStudentsAdded(this.id);
				this.students = [...data].map((item) => {
					return {
						...item,
						nameSearch: toEnglish(item?.name),
					};
				});
				this.allStudents = [...data].map((item) => {
					return {
						...item,
						nameSearch: toEnglish(item?.name),
					};
				});
				this.checkBoxSelected = InGroup.map((s) => s.id);
			} catch (err) {
				console.log(err);
			}
		},

		checkChanged(id) {
			const result = this.checkBoxSelected.find((item) => item === id);

			if (result) {
				this.checkBoxSelected = this.checkBoxSelected.filter((item) => {
					return item !== id;
				});
			} else {
				this.checkBoxSelected.push(id);
			}
		},

		async search() {
			this.students = this.allStudents.filter((s) => {
				return (
					this.formatTextSearch(s.nameSearch).includes(
						this.formatTextSearch(this.searchQuery)
					) ||
					this.formatTextSearch(s.name).includes(
						this.formatTextSearch(this.searchQuery)
					) ||
					this.formatTextSearch(s.unaccent_name).includes(
						this.formatTextSearch(this.searchQuery)
					)
				);
			});
		},

		formatTextSearch(text) {
			if (!text) {
				return '';
			}
			return text.toLowerCase();
		},

		searching() {
			clearInterval(this.typing);
			this.typing = setTimeout(() => {
				this.search();
			}, 1000);
		},

		async addStudents() {
			try {
				await $api.groupClasses.addStudentToGroupClass(this.id, {
					student_ids: this.studentsInGroup.map((s) => s.id),
				});
				await this.$store.dispatch('setAlertMessage', {
					message: 'Thêm học sinh vào lớp nhóm thành công',
				});
				await this.$router.push(`/group-classes`);
			} catch (err) {
				const error =
					err?.response?.data?.errors ||
					err?.message ||
					'Đã có lỗi xảy ra. Vui lòng thử lại';
				await this.$store.dispatch('setAlertMessage', {
					message: error,
					type: 'danger',
				});
			}
		},
		async fetchGroupClassDetail() {
			try {
				const {
					data: { data },
				} = await $api.groupClasses.detail(this.id);
				this.groupClass = data;
			} catch (e) {
				console.log(e);
			}
		},
	},
};
</script>
