
import { Options, Vue, setup } from 'vue-class-component';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import { _ROUTER_NAME } from '@/enums';
import { useStudent } from '@/features/student/useStudent';
import { Menu, MenuItem, MenuItems, MenuButton } from '@headlessui/vue';
import { useStore } from 'vuex';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import CreateStudent from '@/views/record/students/features/createStudent/CreateStudent.vue';
import { Watch } from 'vue-property-decorator';
import Loading from '@/components/common/Loading.vue';
import StudentState from '@/components/common/StudentState.vue';

@Options({
	name: 'Students',
	components: {
		StudentState,
		Loading,
		CreateStudent,
		DaModalFrame,
		DaIcon,
		Menu,
		MenuItem,
		MenuItems,
		MenuButton,
	},
})
export default class Students extends Vue {
	//----------------------- 🤍 Data 🤍 -----------------------//
	change = false;
	students: any[] = [];
	full_students: any[] = [];
	count = {
		closed: 0,
		total: 0,
	};
	modalIsOpen = false;
	error = '';
	status = 'active';

	isLoading = true;
	inProgress = false;

	//----------------------- 🤍 Setup 🤍 -----------------------//
	setup = setup(() => {
		const store = useStore();
		const { error, loading, getStudents, createStudents } = useStudent();
		return { error, loading, getStudents, store, createStudents };
	});

	//----------------------- 🤍 Getters 🤍 -----------------------//
	get routerName() {
		return _ROUTER_NAME;
	}

	get store() {
		return this.setup.store;
	}

	get isAdmin() {
		return this.setup.store.getters.isAdmin;
	}

	get studentsFilter() {
		return this.students;
	}

	//----------------------- 🤍 Created 🤍 -----------------------//
	created() {
		this.fetchStudents();
	}

	async fetchStudents() {
		let role =
			this.store.state.profile.staff.role === 'teacher'
				? 'staff'
				: 'org_admin';
		const studentList = await this.setup.getStudents(role, {
			state: this.status,
		});
		this.isLoading = false;
		this.count = studentList.count;
		this.students = studentList.data;
		this.full_students = studentList.data;
	}

	//----------------------- 🤍 Methods 🤍 -----------------------//
	statusLabel(status: string) {
		if (status === 'active') {
			return 'Kích hoạt';
		} else if (status !== 'active') {
			return 'Khóa';
		}
		return '';
	}

	openCreateModal() {
		this.modalIsOpen = true;
	}

	closeModal() {
		this.modalIsOpen = false;
	}

	searchQuery = '';
	typing: any = null;

	formatTextSearch(text: string) {
		if (!text) {
			return '';
		}
		return text.toLowerCase().trim();
	}

	async search() {
		this.students = this.full_students.filter((s) => {
			return (
				this.formatTextSearch(s.full_name).includes(
					this.formatTextSearch(this.searchQuery)
				) ||
				this.formatTextSearch(s.name).includes(
					this.formatTextSearch(this.searchQuery)
				) ||
				this.formatTextSearch(s.unaccent_full_name).includes(
					this.formatTextSearch(this.searchQuery)
				) ||
				this.formatTextSearch(s.unaccent_name).includes(
					this.formatTextSearch(this.searchQuery)
				)
			);
		});
	}

	isAdminAndAccountant() {
		return this.store.getters.isAdminAndAccountant;
	}

	searching() {
		clearInterval(this.typing);
		this.typing = setTimeout(() => {
			this.search();
		}, 1000);
	}

	async submitStudent(student: any) {
		this.inProgress = true;
		const res = await this.setup.createStudents({ student }, '');
		if (res?.data) {
			await this.fetchStudents();
			this.modalIsOpen = false;
			await this.store.dispatch('setAlertMessage', {
				message: 'Thêm học sinh thành công',
			});
		}
		this.inProgress = false;
		if (res.errors) {
			const error =
				res.errors.join('<br/>') ||
				'Đã có lỗi xảy ra. Vui lòng thử lại';
			await this.store.dispatch('setAlertMessage', {
				message: error,
				type: 'danger',
			});
		}
		if (res?.errors_by_field) {
			this.error = res?.errors_by_field['name'].join('<br/>');
		}
	}

	@Watch('status')
	onStatusChange() {
		this.fetchStudents();
	}
}
