
import { Options, Vue, setup } from 'vue-class-component';
import { _MODAL_TYPE, _ROUTER_NAME } from '@/enums';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import ChangeStatus from '@/views/profile/features/changeStatus/ChangeStatus.vue';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import LeaveReport from '@/views/profile/features/leaveReport/LeaveReport.vue';
import InformationTable from '@/views/profile/features/informationTable/InformationTable.vue';
import Analytics from '@/views/profile/features/analytics/Analytics.vue';
import { useGetEmployee } from '@/views/profile/services/getEmployee';
import { staff } from '@/views/profile/@types/staff.interface';
import { useUpdateStaff } from '@/views/profile/services/updateStaff';
import { Watch } from 'vue-property-decorator';
import { useCloseStaff } from '@/views/profile/services/closeStaff';
import { useActiveStaff } from '@/views/profile/services/activeStaff';
// @ts-ignore
import Popper from 'vue3-popper';
import { useStore } from 'vuex';
import Loading from '@/components/common/Loading.vue';
import ChangeStatusAssignedDaycareClass from '@/views/profile/features/changeStatus/ChangeStatusAssignedDaycareClass.vue';
import ChangeStatusAssigned from '@/views/profile/features/changeStatus/ChangeStatusAssigned.vue';
import Multiselect from '@suadelabs/vue3-multiselect';
import dayjs from 'dayjs';
import { $api } from '@/services';

@Options({
	name: '',
	components: {
		ChangeStatusAssigned,
		ChangeStatusAssignedDaycareClass,
		Loading,
		Analytics,
		InformationTable,
		LeaveReport,
		DaModalFrame,
		ChangeStatus,
		DaIcon,
		Popper,
		Multiselect,
	},
})
export default class EmployeeDetail extends Vue {
	//----------------------- 🤍 Data 🤍 -----------------------//
	modalIsOpen = false;
	modalType = 'LEAVE_REPORT';
	notAnalytics = true;
	activeMode = true;
	successfully = false;

	staff: staff = {
		full_name: '',
		id: 0,
		name: '',
		phone_number: '',
		role: '',
		state: '',
		email: '',
	};

	tab = this.routerName.RECORD_EMPLOYEE_DETAIL_INFO;

	errorAssignedDayClasses = [];
	errorAssigned = [];

	isLoading = true;

	date = {
		month: new Date().getMonth(),
		year: new Date().getFullYear(),
	};

	dataStats = {
		assessment_stats: {
			assessment_less_than_fifty_percent: 0,
			assessment_less_than_seventy_percent: 0,
			assessment_less_than_sixty_percent: 0,
			assessment_more_than_seventy_percent: 0,
		},
		assessments_map: [],
		lesson_report_stats: {
			numbers_of_completed_lesson_report: 0,
			numbers_of_lesson_report: 0,
		},
		media_stats: {
			numbers_of_image: 0,
			numbers_of_video: 0,
		},
		monthly_report_stats: {
			numbers_of_completed_monthly_report: 0,
			numbers_of_monthly_report: 0,
		},
		session_stats: {
			total_created_hours: 0,
			total_scheduled_hours: 0,
			total_unscheduled_hours: 0,
			total_cancelled_hours: 0,
			numbers_of_cancelled_session: 0,
			numbers_of_created_session: 0,
			numbers_of_future_sessions: 0,
			numbers_of_scheduled_session: 0,
			numbers_of_unscheduled_session: 0,
			total_created_days: 0,
			total_scheduled_days: 0,
			total_unscheduled_days: 0,
			total_cancelled_days: 0,
			total_cancelled_by_staff_days: 0,
			total_cancelled_by_student_days: 0,
			total_cancelled_by_staff_hours: 0,
			total_cancelled_by_student_hours: 0,
		},
		student_stats: {
			numbers_of_disabled_assignment: 0,
			numbers_of_student: 0,
		},
		weekly_report_stats: {
			numbers_of_completed_weekly_report: 0,
			numbers_of_weekly_report: 0,
		},
		plan_stats: {
			numbers_of_done_plan: 0,
			numbers_of_plan: 0,
		},
		students: [],
	};

	get dateTitle() {
		return dayjs(new Date(this.date.year, this.date.month, 1)).format(
			'MM/YYYY'
		);
	}

	get datePayload() {
		return dayjs(new Date(this.date.year, this.date.month, 1)).format(
			'YYYY-MM-DD'
		);
	}

	get id() {
		return this.store.state.profile.staff.id;
	}

	//----------------------- 🤍 Setup 🤍 -----------------------//
	setup = setup(() => {
		const store = useStore();
		const { error, loading, getEmployee } = useGetEmployee();
		const { updateStaff__error, updateStaff } = useUpdateStaff();
		const { closeStaff__error, closeStaff } = useCloseStaff();
		const { activeStaff__error, activeStaff } = useActiveStaff();

		return {
			error,
			updateStaff__error,
			closeStaff__error,
			activeStaff__error,
			loading,
			closeStaff,
			updateStaff,
			getEmployee,
			activeStaff,
			store,
		};
	});

	//----------------------- 🤍 Getters 🤍 -----------------------//
	get routerName() {
		return _ROUTER_NAME;
	}

	get ModalType() {
		return _MODAL_TYPE;
	}

	get store() {
		return this.setup.store;
	}

	get isAdmin() {
		return this.setup.store.getters.isAdmin;
	}

	//----------------------- 🤍 Mounted 🤍 -----------------------//
	created() {
		this.fetchStaff();
		this.fetchStaffStats();
	}

	@Watch('date', { immediate: true, deep: true })
	onDateChange(value: any) {
		if (value) {
			this.fetchStaffStats();
		}
	}

	//----------------------- 🤍 Watch 🤍 -----------------------//
	@Watch('staff', { immediate: true, deep: true })
	onStaffChange(value: staff) {
		this.notAnalytics = value.state !== 'active';
	}

	//----------------------- 🤍 Methods 🤍 -----------------------//
	openModel(type: string, activeMode = true) {
		this.successfully = false;
		this.activeMode = activeMode;
		this.modalIsOpen = true;
		this.modalType = type;
	}

	async fetchStaff() {
		const staff = await this.setup.getEmployee(this.id);
		this.staff = staff.data;
		this.isLoading = false;
	}

	updatedAvatar() {
		this.fetchStaff();
	}

	async updateStaff(staff: any) {
		const data = await this.setup.updateStaff(this.id, {
			full_name: staff.full_name,
			name: staff.name,
			phone_number: staff.phone_number,
			emergency_phone_number: staff.emergency_phone_number,
			date_of_birth: staff.date_of_birth,
			role: staff.role,
		});
		if (data && data?.data) {
			this.staff = data?.data;
			await this.store.dispatch('setAlertMessage', {
				message: 'Cập nhật thông tin thành công',
			});
		}
	}

	async closeStaff() {
		const data = await this.setup.closeStaff(this.id);
		const { assigned_day_care_classes, assigned_students } = data;
		if (assigned_students && assigned_students.length) {
			this.errorAssigned = assigned_students;
			this.modalType = _MODAL_TYPE.ERROR;
		} else if (
			assigned_day_care_classes &&
			assigned_day_care_classes.length
		) {
			this.errorAssignedDayClasses = assigned_day_care_classes;
			this.modalType = _MODAL_TYPE.ERROR;
		} else if (data && data?.data) {
			this.staff = data?.data;
			this.successfully = true;
		}
	}

	async activeStaff() {
		const data = await this.setup.activeStaff(this.id);
		if (data && data?.data) {
			this.staff = data?.data;
			this.successfully = true;
		}
	}

	selectTab(index: any) {
		this.tab = index;
	}

	prevDay() {
		const da = dayjs(new Date(this.date.year, this.date.month, 1))
			.subtract(1, 'month')
			.toDate();
		this.date = {
			month: da.getMonth(),
			year: da.getFullYear(),
		};
	}

	nextDay() {
		const da = dayjs(new Date(this.date.year, this.date.month, 1))
			.add(1, 'month')
			.toDate();
		this.date = {
			month: da.getMonth(),
			year: da.getFullYear(),
		};
	}

	async fetchStaffStats() {
		try {
			const {
				data: { data },
			} = await $api.staff.fetchDashboardMyStaff({
				date: this.datePayload,
			});

			const { weekly_report_stats } = data;

			this.dataStats = data;

			this.dataStats.weekly_report_stats = weekly_report_stats || {
				numbers_of_completed_weekly_report: 0,
				numbers_of_weekly_report: 0,
			};
		} catch (err) {
			await this.store.dispatch('setAlertMessage', {
				message: 'Cõ lỗi xảy ra, vui lòng thử lại.',
				type: 'danger',
			});
		}
	}
}
