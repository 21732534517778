<template>
	<div class="fixed inset-y-0 right-0 flex side-over z-50">
		<div class="h-full w-full flex flex-col bg-white shadow-xl">
			<div class="px-6 py-8 bg-blue-50">
				<div class="flex items-start justify-between">
					<div class="flex items-center">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M19.1747 8.87671L18.3916 9.6607C18.9474 10.7987 19.2505 12.0885 19.2505 13.4542C19.2505 18.2592 15.36 22.1538 10.56 22.1538C5.81053 22.1286 1.89474 18.2339 1.89474 13.4289C1.89474 8.62381 5.78526 4.72919 10.5853 4.72919C11.9495 4.72919 13.2126 5.03267 14.3495 5.58904L15.1326 4.80506V3.84405C13.7432 3.18651 12.2021 2.80717 10.5853 2.80717C4.74947 2.80717 0 7.56164 0 13.4036C0 19.2455 4.74947 24 10.5853 24C16.4211 24 21.1705 19.2455 21.1705 13.4036C21.1705 11.785 20.7916 10.2424 20.1347 8.85142H19.1747V8.87671Z"
								fill="#94A3B8"
							/>
							<path
								d="M10.5853 8.6491C10.8126 8.6491 11.04 8.67439 11.2674 8.69968L12.8337 7.13172C12.1263 6.85353 11.3937 6.72708 10.5853 6.72708C6.89684 6.75237 3.91579 9.73656 3.91579 13.4289C3.91579 17.1212 6.89684 20.1307 10.6105 20.1307C14.2989 20.1307 17.3053 17.1212 17.3053 13.4289C17.3053 12.6449 17.1537 11.8862 16.9011 11.1781L15.3347 12.746C15.3853 12.9737 15.3853 13.2013 15.3853 13.4289C15.3853 16.059 13.2379 18.2086 10.6105 18.2086C7.95789 18.2086 5.81053 16.059 5.81053 13.4289C5.81053 10.7987 7.9579 8.6491 10.5853 8.6491Z"
								fill="#94A3B8"
							/>
							<path
								d="M23.9495 3.59115C23.8484 3.31296 23.5705 3.16122 23.2926 3.16122H20.8674V0.708114C20.8674 0.429926 20.6905 0.151739 20.4379 0.0505796C20.16 -0.0505795 19.8568 -1.50739e-08 19.6547 0.202318L17.2547 2.60485C17.1284 2.7313 17.0526 2.90832 17.0526 3.11064V5.61433L11.7474 10.8999C11.3937 10.7229 10.9895 10.6217 10.5853 10.6217C9.04421 10.6217 7.80632 11.8609 7.80632 13.4036C7.80632 14.9463 9.04421 16.1855 10.5853 16.1855C12.1263 16.1855 13.3642 14.9463 13.3642 13.4036C13.3642 12.9737 13.2632 12.5943 13.0863 12.2403L18.3916 6.95469H20.8926C21.0695 6.95469 21.2716 6.87882 21.3979 6.75237L23.7979 4.34984C24 4.14752 24.0505 3.84405 23.9495 3.59115Z"
								fill="#94A3B8"
							/>
						</svg>
						<div class="text-2xl font-bold text-neutral-700 ml-2">
							Mục tiêu SMART
						</div>
					</div>
					<div class="ml-3 h-7 flex items-center">
						<button
							type="button"
							class="
								rounded-md
								text-neutral-400
								hover:text-neutral-700
							"
							@click="$emit('close')"
						>
							<span class="sr-only">Close panel</span>
							<svg
								class="h-6 w-6"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<div class="sideover-content mt-6 relative flex-1 px-4 sm:px-6">
				<div>
					<div class="w-full mt-5">
						<div class="font-semibold text-neutral-600 mb-2">
							Lĩnh vực*
						</div>
						<div class="select-tabs mb-6">
							<Multiselect
								deselect-label="Đã chọn"
								track-by="name"
								:options="
									skills?.map((s) => {
										return {
											nameSearch: toEnglish(s?.name),
											...s,
										};
									})
								"
								v-model="skillSelected"
								:searchable="true"
								label="nameSearch"
								:allow-empty="false"
								selectLabel=""
								placeholder="Chọn lĩnh vực"
							>
								<template #noResult>
									Không tìm thấy kết quả
								</template>
								<template v-slot:option="props">
									<div class="flex flex-nowrap items-center">
										<div>
											{{ props.option.name }}
										</div>
									</div>
								</template>
								<template v-slot:singleLabel="{ option }">
									<div
										class="
											flex flex-nowrap
											items-center
											font-medium
										"
									>
										<div>{{ option.name }}</div>
									</div>
								</template>
							</Multiselect>
						</div>
						<div class="font-semibold text-neutral-600 mb-2">
							Nội dung*
						</div>
						<div class="mb-4">
							<textarea
								placeholder="Nội dung mục tiêu"
								v-model="name"
								:class="errorName ? 'text-error' : ''"
							>
							</textarea>
							<div
								class="text-sienna-600 text-sm"
								v-if="errorName"
							>
								{{ errorName }}
							</div>
						</div>
						<div
							class="font-semibold text-neutral-600 mb-2"
							v-if="enable_goal_assistance_level"
						>
							Mức độ hỗ trợ*
						</div>
						<div
							class="select-tabs mb-6 w-full mr-8"
							v-if="enable_goal_assistance_level"
						>
							<div
								v-for="(item, ass) in assistanceSelecteds"
								:key="ass"
								class="mb-4"
							>
								<Multiselect
									v-if="assistance_levels.length > 0"
									v-model="assistanceSelecteds[ass]"
									track-by="name"
									:options="
										assistance_levels
											?.filter(
												(a) =>
													!assistanceSelecteds.find(
														(s) =>
															s.value === a.value
													)
											)
											?.map((s) => {
												return {
													nameSearch: toEnglish(
														s?.name
													),
													...s,
												};
											})
									"
									:searchable="true"
									label="nameSearch"
									selectLabel=""
									placeholder="Chọn mức độ hỗ trợ"
									selectedLabel=""
									deselectLabel="Ấn đễ bỏ chọn"
									:class="
										errorAssistanceLevels
											? 'text-error'
											: ''
									"
								>
									<template #noResult>
										Không tìm thấy kết quả
									</template>
									<template v-slot:option="props">
										<div
											class="
												flex flex-nowrap
												items-center
											"
										>
											<div>
												{{ props.option.name }}
											</div>
										</div>
									</template>
									<template v-slot:singleLabel="{ option }">
										<div
											class="
												flex flex-nowrap
												items-center
												font-medium
											"
										>
											<div>{{ option.name }}</div>
										</div>
									</template>
								</Multiselect>
								<div
									class="text-sienna-600 text-sm mt-2"
									v-if="errorAssistanceLevels[ass]"
								>
									{{ errorAssistanceLevels[ass] }}
								</div>
								<div
									@click="addAssistanceLevel"
									class="
										font-semibold
										text-yellow-700
										mb-2
										text-sm text-right
										cursor-pointer
									"
								>
									Thêm Mức độ hỗ trợ
								</div>
							</div>
						</div>
						<div
							class="font-semibold text-neutral-600 mb-2"
							v-if="enable_goal_reason"
						>
							Cơ sở hợp lý*
						</div>
						<div class="mb-4" v-if="enable_goal_reason">
							<textarea
								placeholder="cơ sở hợp lý của mục tiêu"
								v-model="reasonInput"
								:class="errorReason ? 'text-error' : ''"
							>
							</textarea>
							<div
								class="text-sienna-600 text-sm"
								v-if="errorReason"
							>
								{{ errorReason }}
							</div>
						</div>
						<div
							class="font-semibold text-neutral-600 mb-2"
							v-if="enable_goal_strategy"
						>
							Chiến lược*
						</div>
						<div class="mb-4" v-if="enable_goal_strategy">
							<textarea
								placeholder="Chiến lược"
								v-model="strategyInput"
								:class="errorStrategy ? 'text-error' : ''"
							>
							</textarea>
							<div
								class="text-sienna-600 text-sm"
								v-if="errorStrategy"
							>
								{{ errorStrategy }}
							</div>
						</div>
						<div
							class="font-semibold text-neutral-600 mb-2"
							v-if="enable_goal_process"
						>
							Quy trình thực hiện*
						</div>
						<div class="mb-4" v-if="enable_goal_process">
							<textarea
								placeholder="Quy trình thực hiện"
								v-model="processInput"
								:class="errorProcess ? 'text-error' : ''"
							>
							</textarea>
							<div
								class="text-sienna-600 text-sm"
								v-if="errorProcess"
							>
								{{ errorProcess }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="px-6 py-4 border-t border-yellow-50">
				<div class="flex justify-end">
					<button
						type="button"
						class="button-p text-center w-32"
						@click="submit"
						:disabled="disableButton"
					>
						Xác nhận
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Multiselect from '@suadelabs/vue3-multiselect';
import { toEnglish } from '@/helper/toEnglish';
import { $api } from '@/services';

export default {
	name: 'AddSmartGoal',

	components: { Multiselect },

	props: {
		goal: {
			type: Object,
			required: true,
		},
		skills: {
			type: Array,
			required: true,
		},
	},

	data() {
		return {
			assistance_levels: [
				{
					name: 'Bằng lời nói trực tiếp',
					value: 5,
				},
				{
					name: 'Bằng lời nói gián tiếp',
					value: 6,
				},
				{
					name: 'Cử chỉ',
					value: 4,
				},
				{
					name: 'Chủ động / sau khi được yêu cầu',
					value: 9,
				},
				{
					name: 'Hỗ trợ thể chất toàn phần',
					value: 1,
				},
				{
					name: 'Hỗ trợ thể chất một phần',
					value: 2,
				},
				{
					name: 'Làm mẫu',
					value: 3,
				},
				{
					name: 'Nhắc hình ảnh',
					value: 7,
				},
				{
					name: 'Nhắc bằng vị trí',
					value: 8,
				},
				{
					name: 'Nhắc kết hợp',
					value: 10,
				},
			],
			name: '',
			processInput: '',
			reasonInput: '',
			strategyInput: '',
			skillSelected: null,
			assistanceSelecteds: [''],

			//
			errorName: '',
			errorStrategy: '',
			errorProcess: '',
			errorReason: '',
			errorAssistanceLevels: '',
		};
	},

	computed: {
		org() {
			return this.$store.state.profile.current_org;
		},

		enable_goal_assistance_level() {
			return this.org.enable_goal_assistance_level;
		},

		enable_goal_process() {
			return this.org.enable_goal_process;
		},

		enable_goal_reason() {
			return this.org.enable_goal_reason;
		},

		enable_goal_strategy() {
			return this.org.enable_goal_strategy;
		},

		disableButton() {
			const {
				name,
				skillSelected,
				assistanceSelecteds,
				enable_goal_assistance_level,
				processInput,
				enable_goal_process,
				reasonInput,
				enable_goal_reason,
				strategyInput,
				enable_goal_strategy,
			} = this;
			return (
				!name ||
				!skillSelected ||
				(enable_goal_assistance_level &&
					(!assistanceSelecteds ||
						!assistanceSelecteds.length ||
						!assistanceSelecteds[0])) ||
				(enable_goal_process && !processInput) ||
				(enable_goal_reason && !reasonInput) ||
				(enable_goal_strategy && !strategyInput)
			);
		},
	},

	watch: {
		goal: {
			immediate: true,
			deep: true,
			handler(value) {
				if (value) {
					this.name = value.name;
					this.processInput = value.process;
					this.reasonInput = value.reason;
					this.strategyInput = value.strategy;
					if (value?.assistance_levels) {
						this.assistanceSelecteds = value.assistance_levels
							.length
							? this.assistance_levels.filter((item) => {
									return value.assistance_levels.find(
										(a) => a === item.name
									);
									// eslint-disable-next-line no-mixed-spaces-and-tabs
							  })
							: [''];
					}
					this.skillSelected = this.skills.find(
						(item) => item.id === value.skill_id
					);
				}
			},
		},
	},

	methods: {
		toEnglish,
		async submit() {
			let payload = {
				name: this.name,
				skill_id: this.skillSelected.id,
				assistance_level: this.assistanceSelecteds
					?.map((i) => i.value)
					.filter((i) => !!i),
				assistance_levels: this.assistanceSelecteds
					?.map((i) => i.value)
					.filter((i) => !!i),
				process: this.processInput,
				reason: this.reasonInput,
				strategy: this.strategyInput,
			};
			try {
				await $api.goal.updateSmart(
					this.goal?.id,
					this.goal?.goal_library_id,
					payload
				);
				this.$emit('updated');
			} catch (err) {
				console.log(err);
			}
		},

		addAssistanceLevel() {
			this.assistanceSelecteds.push('');
		},
	},
};
</script>

<style scoped lang="scss">
@import '../../../assets/styles/responsive';

.side-over {
	width: 100vw;
	padding-left: 0;

	@include media-breakpoint-up(md) {
		width: 600px;
	}
}
.sideover-content {
	height: calc(100vh - 300px);
	overflow-y: auto;
	padding-bottom: 100px;
}
</style>
